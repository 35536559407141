import { useState } from "react";
import { Button } from "react-bootstrap";
//import { get, post, delete } from "superagent";
import * as superagent from 'superagent';

function Header({ title, isPaused }: { title: string, isPaused: boolean }) {

  const logout = async (e: any) => {

    await superagent.delete(`${process.env.REACT_APP_API_URL}/api/logout`)
      .withCredentials()
      .then(((res: any) => {
        window.location.replace("/");
      }), (err: any) => {
        if (err.response) {
          console.error(err.response)
        }
      })
  };

  return (
    <div className="App">

      <header>

        <nav className={`navbar ${isPaused ? "paused" : ""}`} id="nav-primary">
          <div className="container">
            <div>
            <h2 className="navbar-brand">{title}</h2>
            {isPaused ? <small><i className="bi bi-pause-btn-fill"/>&nbsp;Case study is currently paused.</small> : null}
            </div>
          </div>
          <div style={{ "textAlign": "right" }}>
            <Button type="submit" onClick={logout}>Logout</Button>&nbsp;&nbsp;
          </div>
        </nav>

      </header>

    </div>
  );
}

export default Header;
