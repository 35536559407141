
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { get } from "superagent";
import { Alert } from "react-bootstrap";

import Header from "../header";
import Page from "./page";
import { StudentCaseData } from "../../types";
import { socket } from "../../";

function StudentView() {

  const [ caseData, setCaseData ] = useState<StudentCaseData>();
  const [ isPaused, setIsPaused ] = useState<boolean>(false);
  const [ pageIds, setPageIds ] = useState<Array<number>>([]);
  const [ page, setPage ] = useState<any|undefined>();
  const [ showAlert, setShowAlert ] = useState<boolean>(false);
  const [ alertMessage, setAlertMessage ] = useState<string>("");

  let { caseId, pageId } = useParams();
  
  // Get and set caseData
  const getCase = async () => {

    await get(`${process.env.REACT_APP_API_URL}/api/case/student/${caseId}`)
    .withCredentials()
    .then((res: any) => { 

      setCaseData(res.body.data.caseData);
      setPageIds(res.body.data.caseData.pageIds);
      setIsPaused(res.body.data.caseData.isPaused);

      // Set case in socket
      socket.emit("set-case", {caseId});

    })
    .catch((err: any) => { console.log(err) });
    
  };

  const getPage = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/page/${pageId}`)
    .withCredentials()
    .then((res: any) => { 
      setPage(res.body.data);
    })
    .catch((err: any) => { 
      if (err.response) {
        setShowAlert(true);
        setAlertMessage(err.response.text ? err.response.text : "There was an error getting the page.")
      }
     });
  };

  useEffect(() => {
    getCase();
    getPage();

    socket.on("paused", (data: any) => {
      setIsPaused(data.isPaused)
    });

  }, []);

  if (caseData && pageIds.length > 0) {

    return (
      <div>

        <Header title={caseData.name} isPaused={isPaused} />
        {page ? <Page caseData={caseData} pageData={page} isPaused={isPaused} pageIds={pageIds}/> : null}

        <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
          <Alert.Heading>Error</Alert.Heading>
          <p>{alertMessage}</p>
        </Alert>

      </div>
    )

  }

  else {
    return (
      <div>Case not found.</div>
    )
  }
 
}

export default StudentView;
