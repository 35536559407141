
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { get } from "superagent";

import Login from "./login";

const AuthorizeRedirect = () => {

  const [isInstructor, setIsInstructor] = useState(false);
  const [userValidated, setUserValidated] = useState(false);
  const [caseId, setCaseId] = useState(undefined);
  const [pageId, setPageId] = useState(undefined);

  // Check if user is already logged in
  const checkForSession = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/session/`)
      .withCredentials()
      .then((res: any) => {
        setUserValidated(true);
        setIsInstructor(res.body.isInstructor);
        setCaseId(res.body.caseId);
        setPageId(res.body.pageId);
      }).catch((err: any) => {
        console.error(err);
      });
  };

  useEffect(() => {
    checkForSession();
  });

  if (userValidated) {
    if (isInstructor) {
      return (
        <div>
          <Navigate to="/instructor" />
        </div>
      );
    }
    else if ((caseId || caseId === 0) && (pageId || pageId === 0)) {
      return (
        <div>
          <Navigate to={`/${caseId}/${pageId}`} />
        </div>
      );
    }
  }

  // Return login page if not validated
  return (
    <Login />
  );

}

export default AuthorizeRedirect;
