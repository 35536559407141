import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Alert } from "react-bootstrap";
import { get, put } from "superagent";

import PageDragAndDrop from "./page-drag-and-drop";
import Users from "./users";
import Results from "./results";
import Header from "../header";

import { Case } from "../../types";
import { socket } from "../..";

// TODO:
// 3. add page preview

function EditCasePage() {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  let { caseId } = useParams();
  const [caseStudy, setCaseStudy] = useState<Case>();
  const [editingName, toggleEditName] = useState(false);
  const [editingDescription, toggleEditDescription] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [descriptionValue, setDescriptionValue] = useState("");

  // Get all case studies
  const getCase = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/case/${caseId}`)
      .withCredentials()
      .then((res: any) => {
        setCaseStudy(res.body.data);
        return;
      }).catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });
  };

  const updateCase = async () => {
    await put(`${process.env.REACT_APP_API_URL}/api/case/${caseId}`)
      .withCredentials()
      .send({name: nameValue, description: descriptionValue})
      .catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });

      await getCase();

      toggleEditName(false);
      toggleEditDescription(false);
  };

  const pause = (pause: boolean) => {
    socket.emit("pause", {caseId, pause});
  }

  // Converts date string to MM/DD/YYYY HH:SS
  const getReadableDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString([], { hour: '2-digit', minute: '2-digit' });
  }

  useEffect(() => {
    getCase();
  }, []);

  useEffect(() => {
    if (caseStudy) {
      setNameValue(caseStudy.name);
      caseStudy.description ? setDescriptionValue(caseStudy.description) : setDescriptionValue("No description");
      setIsPaused(caseStudy.isPaused);
    }
  }, [caseStudy]);

  return (
    <div>

      <Header title="Case Study Builder" isPaused={false}/>

      <main role="main">

        <div className="container">

          <p><a href="/instructor">&laquo; Back to all Case Studies</a></p>

          <div className="row align-items-center">
            
            <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
              <Alert.Heading>Error</Alert.Heading>
              <p>{alertMessage}</p>
            </Alert> 

            {caseStudy ?
              <div>
                <div className="col">

                  {/* Edit name */}
                  {editingName ? 
                    <div className="mb-2">
                      <label className="form-label">Edit page title:</label><br/>
                      <input 
                        className="form-control mb-2"
                        defaultValue={nameValue} 
                        onChange={(e) => setNameValue(e.target.value)}/>
                      <button className="btn btn-sm btn-primary" onClick={updateCase}>
                        <i className="bi bi-pencil-square"></i>&nbsp;&nbsp;Save
                      </button>&nbsp;
                      <button 
                        className="btn btn-sm btn-secondary" 
                        onClick={() => {
                          toggleEditName(false);
                          setNameValue(caseStudy.name)
                        }}>
                        <i className="bi bi-x-circle"/>&nbsp;&nbsp;Cancel
                      </button>
                    </div> :
                    <h1 style={{marginTop: "0px"}}>
                      {caseStudy.name} <i className="bi bi-pencil-square" onClick={() => toggleEditName(true)}/>
                    </h1>
                  }

                  <div className="float-end" style={{width: "170px"}}>

                    {isPaused ? 
                      <button 
                        className={`btn btn-sm btn-secondary`} 
                        onClick={() => {
                          pause(false);
                          updateCase();
                        }}>
                        <i className="bi bi-play"/>
                        &nbsp;Resume Case Study
                      </button> : 
                      <button 
                        className={`btn btn-sm btn-warning`} 
                        onClick={() => {
                          pause(true);
                          updateCase();
                        }}>
                        <i className="bi bi-pause"/>
                        &nbsp;Pause Case Study
                      </button>
                    }
                  </div>

                  <p>Last modified: {getReadableDate(caseStudy.modifyDate)}</p>
                  
                  {/* Edit description */}
                  { editingDescription ? 

                    <div className="mb-2">
                      <label className="form-label">Edit page description:</label>
                      <input 
                        type="email" 
                        className="form-control mb-2" 
                        id="editDescription" 
                        value={descriptionValue}
                        onChange={(e) => setDescriptionValue(e.target.value)}/>
                      <button className ="btn btn-sm btn-primary" onClick={updateCase}>
                        <i className="bi bi-pencil-square"/>&nbsp;&nbsp;Save
                      </button>&nbsp;
                      <button className="btn btn-sm btn-secondary" onClick={() => {
                          toggleEditDescription(false)
                          setDescriptionValue(caseStudy.description)
                        }}>
                        <i className="bi bi-x-circle"/>&nbsp;&nbsp;Cancel
                      </button>
                    </div> :
                    <p>
                      {caseStudy.description + " "} 
                      <i onClick={() => toggleEditDescription(true)} className="bi bi-pencil-square"/>
                    </p>
                  }
           
                  <hr className="my-4"/>

                  <div className="row row-cols-2 gx-5">
                    <PageDragAndDrop />
                    <Users />
                  </div>

                  <Results/>

                  <br />
                </div>
              </div> : null}
          </div>
        </div>
      </main>
    </div>
  );
}

export default EditCasePage;
