import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Alert } from "react-bootstrap";
import { get, put } from "superagent";

import { Page } from "../../types";


interface TimerProps {
  originalTime: string;
}

const Timer = ({originalTime}: TimerProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [editingTime, toggleEditTime] = useState(false);
  const [updatedTimeValue, setUpdatedTimeValue] = useState<string>(originalTime);

  let { pageId } = useParams();

  const updateTime = async () => {
    await put(`${process.env.REACT_APP_API_URL}/api/page/time/${pageId}`)
      .withCredentials()
      .send({time: updatedTimeValue})
      .catch((err: any) => {
        if (err.response) {
          setUpdatedTimeValue(originalTime);
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });
  };

  return (
    <div>

      <Alert variant="danger" show={showAlert} onClose={() =>
        setShowAlert(false)} dismissible>
        <Alert.Heading>Error</Alert.Heading>
        <p>{alertMessage}</p>
      </Alert> 

        <h3>Timer</h3>
        
        {editingTime ? 
          <div className="mb-2">
            <label className="form-label">Edit timer length: (in minutes):</label><br/>
            <div className="col col-2">
              <input 
                type="number"
                className="form-control mb-2"
                defaultValue={updatedTimeValue} 
                onChange={(e) => setUpdatedTimeValue(e.target.value)}/>
            </div>
            <button className="btn btn-sm btn-primary" onClick={() => {
              updateTime();
              toggleEditTime(false);
            }}>
              <i className="bi bi-pencil-square"></i>&nbsp;&nbsp;Save
            </button>&nbsp;
            <button 
              className="btn btn-sm btn-secondary" 
              onClick={() => {
                toggleEditTime(false);
                setUpdatedTimeValue(originalTime)}
              }>
              <i className="bi bi-x-circle"/>&nbsp;&nbsp;Cancel
            </button>
          </div> :
          <p style={{marginTop: "0px"}}>
            {updatedTimeValue} minutes &nbsp;
            <i className="bi bi-pencil-square" onClick={() => toggleEditTime(true)}/>
          </p>
        }

      <hr className="my-4" />

    </div>
  );
}

export default Timer;
