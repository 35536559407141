import { Document } from "../../types";
import { fileExtensions } from "../../helpers/fileExtensions";

type DocumentProps = {
  documents: Array<Document>,
  caseId: string
}

function PageDocuments(props: DocumentProps) {
  const { documents, caseId } = props;

  return (
    <div className="documents">
      <h2>Documents</h2>

      <table className="table table-sm table-striped table-hover doc-table">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Type</th>
            <th scope="col">Size</th>
          </tr>
        </thead>
        <tbody>

          {documents.map((document) => {
            const extensionIcon = fileExtensions[document.extension] ? fileExtensions[document.extension] : "bi-question-circle";
            return (
              <tr key={document.id}>
                <td>
                  <a 
                    href={`${process.env.REACT_APP_API_URL}/api/document/${caseId}/${document.id}`} 
                    target="__blank">
                    {document.title}
                  </a>
                </td>
                <td><i className={`bi ${extensionIcon}`}/></td>
                <td>{document.size}</td>
              </tr>
            );
          })}

        </tbody>
      </table>
    </div>
  );
}

export default PageDocuments;
