import { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

import { socket } from "../../";

type TimerProps = {
  timeRemain: number,
  pageId: number,
  isPaused: boolean,
  isPreviewing?: boolean
  setPreviewTimeRemain: (seconds: number) => void;
}

export type TimeFormat = {
  minutes: number,
  seconds: number
};

export function Timer(props: TimerProps) {
  let { timeRemain, pageId, isPaused, isPreviewing, setPreviewTimeRemain } = props;
  
  const startingMinutes = Math.floor(timeRemain / 60);
  const startingSeconds = timeRemain - startingMinutes * 60;

  const startTime: TimeFormat = {minutes: startingMinutes, seconds: startingSeconds};
  const { minutes, seconds } = startTime;
  const [[mins, secs], setTime] = useState<Array<number>>([minutes, seconds]);
  const [showingTimeUpMessage, toggleShowingTimeUpMessage] = useState<boolean>(false);

  let timerId: any = null;

  const tick = () => {

    // Save time via sockets every 10 seconds in case browser is closed
    if (secs % 10 === 0) {
      if (isPreviewing) {
        // If previewing, send time back to local state
        setPreviewTimeRemain((mins*60) + secs);
      }
      // Otherwise, save time via sockets
      else {
        socket.emit("save-time", {pageId, timeRemain: (mins*60) + secs}) // save time in seconds
      }
    }

    if (mins === 0 && secs === 0) {
      setTime([59, 59]);
    } else if (secs === 0) {
      setTime([mins - 1, 59]);
    } else {
      setTime([mins, secs - 1]);
    }
  };

  useEffect(() => {
    if (!isPaused) {
      if ((mins + secs) > 0) {
        timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
      }
      // Time has run out
      else if (timeRemain !== 0 && (mins + secs) === 0) {
        toggleShowingTimeUpMessage(true);
      }
    }
  }, [mins, secs]);

  // Stop timer on component unmount
  useEffect(() => { 
    return () => clearInterval(timerId);
  }, []);

  // Stop timer on component unmount
  useEffect(() => { 
    if (!isPaused) {
      setTime([startTime.minutes, startTime.seconds])
    }
  }, [isPaused]);

  const completeTimer = () => {
    if (isPreviewing) {
      setPreviewTimeRemain(0);
    }
    else {
      socket.emit("save-time", {pageId, timeRemain: 0})
    }
    toggleShowingTimeUpMessage(false);
  }

  return (
    <div>

      <Modal show={showingTimeUpMessage} onHide={completeTimer}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Time up</h4>
        </Modal.Header>
        <Modal.Body>
          <p>Time has run out to answer questions on this page</p>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={completeTimer}>Ok</Button>
          </Modal.Footer> 
      </Modal>

      <strong>Time Left:</strong> 
      <span id="time-remaining"> {mins}:{secs}</span>
    </div>
  );
}

export default Timer;