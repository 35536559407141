export const fileExtensions: {[key: string]: string} = {
  ".doc": "bi-file-earmark-word",
  ".docx": "bi-file-earmark-word",
  ".pdf": "bi-file-earmark-pdf",
  ".ppt": "bi-file-earmark-ppt",
  ".pptx": "bi-file-earmark-ppt",
  ".jpg": "bi-file-earmark-image",
  ".JPG": "bi-file-earmark-image",
  ".png": "bi-file-earmark-image",
  ".jpeg": "bi-file-earmark-image",
  ".mov": "bi-film",
  ".mp3": "bi-file-earmark-music",
  ".mp4": "bi-film",
  ".txt": "bi-filetype-txt",
  ".csv": "bi-file-earmark-excel",
  ".xlsx": "bi-file-earmark-excel"
}
