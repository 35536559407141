import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { get, put, post, del, patch } from "superagent";

import { Page, Question } from "../../types";
import Header from "../header";
import Questions from "./questions";
import TextEditor from "./text-editor";
import Documents from "./documents";
import Timer from "./timer";

function EditPage() {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [page, setPage] = useState<Page>();
  const [pages, setPages] = useState<Array<Page>>([]);
  const [questions, setQuestions] = useState<Array<Question>>([]);
  const [updatedPageValues, setUpdatedPageValues] = useState<Page>();
  const [editingFeedback, toggleEditFeedback] = useState(false);
  const [editingTitle, toggleEditTitle] = useState(false);

  let { caseId, pageId } = useParams();

  // Get page data
  const getPage = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/page/${pageId}`)
      .withCredentials()
      .then((res: any) => {

        setPage(res.body.data);
        setUpdatedPageValues(res.body.data);

        if (res.body.data.questions) {
          setQuestions(res.body.data.questions);
        }
        return;
      }).catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });
  };

  // Get case pages
  const getPages = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/page/case/${caseId}`)
    .withCredentials()
    .then((res: any) => {
      setPages(res.body.pages);
      return;
    }).catch((err: any) => {
      if (err.response) {
        setShowAlert(true);
        setAlertMessage(err.response.text);
      }
      console.error(err);
    });
  };

  const updatePage = async () => {
    await put(`${process.env.REACT_APP_API_URL}/api/page/${pageId}`)
      .withCredentials()
      .send({...updatedPageValues})
      .catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });

    await getPage();
  };

  const addQuestion = async (q: Question, choices: Array<string>) => {
    await post(`${process.env.REACT_APP_API_URL}/api/question?pageId=${pageId}`)
      .send({...q, choices})
      .withCredentials()
      .then((res: any) => { getPage(); return false; })
      .catch((err: any) => { return err; });
  }

  const updateQuestion = async (q: Question, choices: Array<string>) => {
    return patch(`${process.env.REACT_APP_API_URL}/api/question/${q.id}?pageId=${pageId}`)
      .send({...q, choices})
      .withCredentials()
      .then((res: any) => { getPage(); return false; })
      .catch((err: any) => { return err; });
  }

  const deleteQuestion = async (questionId: number) => {
    await del(`${process.env.REACT_APP_API_URL}/api/question/${questionId}`)
      .withCredentials()
      .then((res: any) => { getPage(); return false; })
      .catch((err: any) => { return err; });
  }

  useEffect(() => {
    getPage();
    getPages();
  }, []);

  if (page && updatedPageValues) {

    return (
      <div>
        
        <Header title="Case Study Builder" isPaused={false}/>

        <main role="main">
          <div className="container">
            <div className="row align-items-center">
              
              <p>
                <a href={`/instructor/${caseId}`}>« Back to Case Study Overview</a>
              </p>
              
              <div className="row align-items-center">
                <Alert variant="danger" show={showAlert} onClose={() =>
                  setShowAlert(false)} dismissible>
                  <Alert.Heading>Error</Alert.Heading>
                  <p>{alertMessage}</p>
                </Alert>

                <div>
                  <div className="col">
                    
                    <p className="float-end" style={{marginTop: "1rem"}}>
                      <Link 
                        to={`/instructor/${caseId}/${pageId}/preview`} 
                        target="__blank" 
                        style={{textDecoration: "none"}}>
                        <small>Preview Page <i className="bi bi-eye"/></small>
                      </Link>
                    </p>

                    {editingTitle ? 
                      <div className="mb-2">
                        <label className="form-label">Edit page title:</label><br/>
                        <input 
                          className="form-control mb-2"
                          defaultValue={updatedPageValues.title} 
                          onChange={(e) => setUpdatedPageValues({
                            ...updatedPageValues, 
                            title: e.target.value
                          })}/>
                        <button className="btn btn-sm btn-primary" onClick={() => {
                          updatePage();
                          toggleEditTitle(false);
                        }}>
                          <i className="bi bi-pencil-square"></i>&nbsp;&nbsp;Save
                        </button>&nbsp;
                        <button 
                          className="btn btn-sm btn-secondary" 
                          onClick={() => {
                            toggleEditTitle(false);
                            setUpdatedPageValues({
                              ...updatedPageValues, 
                              title: page.title
                            })}
                          }>
                          <i className="bi bi-x-circle"/>&nbsp;&nbsp;Cancel
                        </button>
                      </div> :
                      <h1 style={{marginTop: "0px"}}>
                        {page.title ? page.title : "Untitled"} 
                        &nbsp;<i className="bi bi-pencil-square" onClick={() => toggleEditTitle(true)}/>
                      </h1>
                    }

                    <br/>

                    <TextEditor 
                      updatedPageValues={updatedPageValues} 
                      handleUpdatePage={updatePage}
                      handleSetUpdatedPageValues={setUpdatedPageValues}
                    />

                    <Questions 
                      questions={questions} 
                      pages={pages} 
                      handleAddQuestion={addQuestion}
                      handleDeleteQuestion={deleteQuestion}
                      handleUpdateQuestion={updateQuestion}
                    />
        
                    <hr className="my-4" />
                    
                    <Documents />

                    <hr className="my-4" />
                    
                    {/* Only allow time on pages with questions */}
                    {questions.length > 0 ? 
                      <Timer originalTime={page.time}/>
                    : null }

                    <h3>Page feedback</h3>

                    {editingFeedback ? 
                      <div className="mb-2">
                        <label className="form-label">Edit page feedback:</label><br/>
                        <input 
                          className="form-control mb-2"
                          defaultValue={updatedPageValues.feedback} 
                          onChange={(e) => setUpdatedPageValues({
                            ...updatedPageValues, 
                            feedback: e.target.value
                          })}/>
                        <button className="btn btn-sm btn-primary" onClick={() => {
                          updatePage();
                          toggleEditFeedback(false);
                        }}>
                          <i className="bi bi-pencil-square"></i>&nbsp;&nbsp;Save
                        </button>&nbsp;
                        <button 
                          className="btn btn-sm btn-secondary" 
                          onClick={() => {
                            toggleEditFeedback(false);
                            setUpdatedPageValues({
                              ...updatedPageValues, 
                              feedback: page.feedback
                            })}
                          }>
                          <i className="bi bi-x-circle"/>&nbsp;&nbsp;Cancel
                        </button>
                      </div> :
                      <p style={{marginTop: "0px"}}>
                        {page.feedback ? page.feedback : "[none]"} &nbsp;
                        <i className="bi bi-pencil-square" onClick={() => toggleEditFeedback(true)}/>
                      </p>
                    }

                    <br/>
                    <br/>
                    <br/>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
  else { return (<div></div>)}
}

export default EditPage;
