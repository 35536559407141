
import {  useState } from "react";
import { Form, Row, InputGroup, Button } from "react-bootstrap";
import { post } from "superagent";

import Header from "./header";

function Login() {

  const [validated, setValidated] = useState(false);
  const [error, setError] = useState("");
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const login = async (e: any) => {
    const { username, password } = values;
    e.preventDefault();
    e.stopPropagation();
    await post(`${process.env.REACT_APP_API_URL}/api/login/?username=${username}&password=${password}`)
      .withCredentials()
      .then(((res: any) => {
        setValidated(true);
        // Redirect to student or instructor pages
        if (res.body.isInstructor) {
          window.location.replace(`/instructor`);
        } else {
          window.location.replace(`/${res.body.caseId}/${res.body.pageId}`);
        }

      }), (err: any) => {
        if (err.response) {
          console.log(err);
          setError(err.response.text);
        }
        setValidated(false);
      })
  };

  return (
    <div>
    
      <Header title="Log into Case Study" isPaused={false}/>

      <main role="main">

        <div className="container">

          <div className="row justify-content-md-center">
            <div className="col-5">

              <Form validated={validated} onSubmit={login}>
                <Row className="mb-3">

                  <Form.Group controlId="validationUsername">
                    <Form.Label>Username</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="username"
                        placeholder="Username"
                        aria-describedby="inputGroupPrepend"
                        onChange={(e) => setValues((values) => ({
                          ...values,
                          username: e.target.value,
                        }))}
                        required
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="validationPassword">
                    <Form.Label>Password</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="password"
                        placeholder="password"
                        aria-describedby="inputGroupPrepend"
                        onChange={(e) => setValues((values) => ({
                          ...values,
                          password: e.target.value,
                        }))}
                        required
                      />

                    </InputGroup>

                  </Form.Group>
                </Row>
                <div className="text-danger">{error}</div><br />

                <Button type="submit" onClick={login}>Login</Button>
              </Form>

            </div>
          </div>
        </div>

      </main>

    </div>
  );

}

export default Login;
