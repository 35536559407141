import { useSelector } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  useParams
} from "react-router-dom";

import './App.css';

import Authorize from "./components/authorize";
import AuthorizeRedirect from "./components/authorize-redirect";
import EditCasePage from "./components/instructor/edit-case";
import EditPage from "./components/instructor/edit-page";
import PreviewPage from "./components/instructor/preview-page";
import InstructorPage from "./components/instructor";
import StudentView from "./components/student";

const App = () => {

  return (
    <div>  
      <Routes>

        {/* Student Routes */}
        <Route path='/:caseId'>
          <Route path='/:caseId/:pageId' element={
            <Authorize role="student">
              <StudentView/>
            </Authorize>
          }/>
        </Route>
        
        {/* Instructor Routes */}
        <Route path="instructor">
          <Route path="" element={
            <Authorize role="instructor">
              <InstructorPage />
            </Authorize>
          }/>
          <Route path=":caseId" element={
            <Authorize role="instructor">
              <EditCasePage />
            </Authorize>
          }/>
          <Route path=":caseId/:pageId" element={
            <Authorize role="instructor">
              <EditPage />
            </Authorize>
          }/>
          <Route path=":caseId/:pageId/preview" element={
            <Authorize role="instructor">
              <PreviewPage />
            </Authorize>
          }/>
        </Route>

        <Route path='/' element={<AuthorizeRedirect/>}/>

      </Routes> 
    </div>
  );
}

export default App;
