
import { useEffect, useState } from "react";
import { get } from "superagent";

import Login from "./login";

function Authorize({ children, role }: { children: JSX.Element, role: string }) {

  const [userValidated, setUserValidated] = useState(false);
  const [roleValidated, setRoleValidated] = useState<boolean>(false);
  const [sessionChecked, setSessionChecked] = useState<boolean>(false);

  // Check if user is already logged in
  const checkForSession = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/session/`)
      .withCredentials()
      .then((res: any) => {

        const isInstructor = res.body.isInstructor;
        setUserValidated(true);

        if ((role === "instructor" && isInstructor) || (role === "student" && !isInstructor)) {
          setRoleValidated(true);  
        }

        setSessionChecked(true);
      }).catch((err: any) => {
        setUserValidated(false);
        setSessionChecked(true);
      });
  };

  useEffect(() => {
    checkForSession();
  });

  // Return student or admin pages if validated
  if (userValidated && roleValidated) {
    return children;
  }

  // Return login page if not validated
  else if (sessionChecked) {
    return (
      <Login />
    );
  }

  else {
    return (
      <div></div>
    )
  }

}

export default Authorize;
