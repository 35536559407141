import update from 'immutability-helper'
import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router";
import { Button, Modal, Toast, Alert } from "react-bootstrap";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { del, get, post, patch } from "superagent";

import Card from "./card";

export interface NewPage {
  order: number;
  id: string;
  title: string;
}

interface PageOrder { 
  id: string,
  pageNo: number
}

const style = {
  width: 400,
}

export interface Item {
  id: number
  text: string
}

export interface ContainerState {
  cards: Item[]
}

export const PageDragAndDrop = ()  => {
  const [selectedPageId, setSelectedPageId] = useState<string>("");
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showToast, setShowToast] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  let { caseId } = useParams();
  const [ pages, setPages ] = useState<Array<any>>([]);

  useEffect(() => {
    getPages();
  }, []);

  const handleError = (err: any) => {
    if (err.response.text) {
      setShowAlert(true);
      setAlertMessage(err.response.text);
    }
    else {
      setShowAlert(true);
      setAlertMessage("An error occured.");
    }
    console.error(err);
  }

  // Updates order
  const movePage = useCallback((dragIndex: number, hoverIndex: number) => {
    setPages((prevPages: Item[]) =>
      update(prevPages, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevPages[dragIndex] as Item],
        ],
      }),
    )
  }, []);

  // Updated pages after drop
  const dropPage = async() => {

    const newPageOrder = pages.map((page, i) => {
      return ({
        pageNo: i,
        id: page.id
      });
    });
    await patch(`${process.env.REACT_APP_API_URL}/api/page/order/`)
    .withCredentials()
    .send(newPageOrder)
    .then((res: any) => { getPages() })
    .catch((err: any) => { handleError(err) });
  }

  const getPages = async() => {
    await get(`${process.env.REACT_APP_API_URL}/api/page/case/${caseId}`)
    .withCredentials()
    .then((res: any) => { setPages(res.body.pages) })
    .catch((err: any) => { handleError(err) });
  }

  // Add new page
  const addPage = async () => {
    await post(`${process.env.REACT_APP_API_URL}/api/page/${caseId}`)
      .withCredentials()
      .send({pageNo: pages.length + 1})
      .then((res: any) => { getPages() }) // Refresh pages
      .catch((err: any) => { handleError(err) });
  }
  
  const deletePage = async (pageId: string) => {
    await del(`${process.env.REACT_APP_API_URL}/api/page/${pageId}?caseId=${caseId}`)
      .withCredentials()
      .then((res: any) => {
        getPages();
        setShowToast(true);
      }).catch((err: any) => { handleError(err) });
  }

  return (
    <div className="col">

      <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Delete Page</h4>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this page?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowConfirmDelete(false)}>Cancel</Button>
          <Button variant="btn btn-danger" onClick={() => {
            deletePage(selectedPageId);
            setShowConfirmDelete(false);
            }}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <div style={{ position: "absolute", bottom: "40px", left: "40px", color: "white" }}>
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          animation={true}
          delay={3000}
          bg={'success'}
          autohide>
          <Toast.Body>Page deleted!</Toast.Body>
        </Toast>
      </div>

      <p className="float-end">
       <Button variant="primary" className="mt-2" onClick={() => addPage()}>Add page + </Button>
      </p>

      <h3>Pages</h3>

      <div className="clearfix"></div>

      {pages.length === 0 ? <p>You don't have any pages yet.</p> : <p>Drag to reorder pages</p>}

      <DndProvider backend={HTML5Backend}>
        <ul className="list-group">
          {pages.map((page, i) => { 
            return <Card
              key={page.id}
              index={i}
              id={page.id}
              dropPage={dropPage}
              movePage={movePage}
            > 

              <div draggable="true" style={{opacity: 1, cursor: "move"}} className="page-list-draggable">
                <div className="list-group-item d-flex justify-content-between align-items-center">
                  {page.title ? page.title : "New Page"}
                  <div>
                    <a href={`/instructor/${caseId}/${page.id}`}>
                      <button className="btn btn-sm btn-primary">
                        <i className="bi bi-pencil-square"></i>&nbsp;&nbsp;Edit
                      </button>
                    </a>
                    &nbsp;
                    <button 
                      className="btn btn-sm btn-danger" 
                      onClick={() => {
                        setSelectedPageId(page.id);
                        setShowConfirmDelete(true)}
                      }>
                      <i className="bi bi-x-circle-fill"></i>&nbsp;&nbsp;Delete
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          })}

        </ul>

      </DndProvider>

      <br/>
      
      <Alert variant="danger" show={showAlert} onClose={() => setShowAlert(false)} dismissible>
        <Alert.Heading>Error</Alert.Heading>
        <p>{alertMessage}</p>
      </Alert>

    </div>

  );

}

export default PageDragAndDrop;
