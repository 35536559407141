import { useState, MouseEvent, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { post, get, put } from "superagent";
import { Question, Choice, Response } from "../../types";

type QuestionsProps = {
  questions: Array<Question>;
  pageId: number|string;
  isPaused: boolean;
  timeIsUp: boolean;
  isLastPage: boolean;
  handleSetQuestionsComplete: (isComplete: boolean) => void;
  handleSetNextPageId: (nextPageId: number) => void;
}

type QuestionAnswers = {
  [questionId: number]: string|number
}

const Questions = ({ 
  questions, 
  pageId, 
  isPaused, 
  timeIsUp, 
  isLastPage,
  handleSetQuestionsComplete,
  handleSetNextPageId
 }: QuestionsProps) => {

  const [verifying, toggleVerify] = useState(false);
  const [feedback, setFeedback] = useState<string>("");
  const [showingLimitMessage, toggleShowingLimitMessage] = useState<boolean>(false);
  const [showingFeedback, toggleShowingFeedback] = useState<boolean>(false);
  const [currentQuestionId, setCurrentQuestionId] = useState<number>();
  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswers>({});
  const [responses, setResponses] = useState<QuestionAnswers>({});

  const getResponses = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/response/user/page/${pageId}`)
    .withCredentials()
    .then((res: any) => {

      if (res.body.data && res.body.data.length > 0) {
        let newResponses: QuestionAnswers = {};

        // Sort responses by question ID
        res.body.data.forEach((res: Response) => {
          newResponses[res.questionId] = res.responseText;
        });

        // Set current question ID
        const lastQuestionAnswered = questions.find(q => !newResponses[q.id]);

        setCurrentQuestionId(lastQuestionAnswered ? lastQuestionAnswered.id : questions[0].id);
        setResponses(newResponses);

        if (Object.keys(newResponses).length === questions.length) {
          handleSetQuestionsComplete(true);
        }
      }

      else {
        setCurrentQuestionId(questions[0].id);
      }
      return;
    }).catch((err: any) => {
      console.error(err);
    });
  };


  // Submit response
  const handleSubmit = async (e: MouseEvent<HTMLButtonElement>) => {
    toggleVerify(false);

    if (currentQuestionId) {

      await post(`${process.env.REACT_APP_API_URL}/api/response/${currentQuestionId}`)
      .send({responseText: questionAnswers[currentQuestionId]})
      .withCredentials()
      .then((res: any) => {
        if (res.body.feedback) {
          setFeedback(res.body.feedback);
          toggleShowingFeedback(true);
        }
        if (res.body.nextPageId && !isLastPage) {
          handleSetNextPageId(res.body.nextPageId)
        }
        getResponses();
        return;
      }).catch((err: any) => {
        console.error(err);
      });
    }

    if (showingLimitMessage) {
      toggleShowingLimitMessage(false);
    }

  }

  const handleUpdateQuestionAnswer = (questionId: number, answer: string|number) => {
    let newQuestionAnswer: QuestionAnswers = Object.assign({}, questionAnswers);
    newQuestionAnswer[questionId] = answer;
    setQuestionAnswers(newQuestionAnswer);
  }

  const handleUpdateTextarea = (e: any, questionId: number) => {
    // Prevent limit from reaching 2,000 characters
    if (e.target.value.length > 2000) {
      e.preventDefault();
      toggleShowingLimitMessage(true);
      return;
    }

    else if (e.target.value.length <= 2000 && showingLimitMessage) {
      toggleShowingLimitMessage(false);
    }

    // Auto-resize textarea
    var offset = e.target.offsetHeight - e.target.clientHeight;
    e.target.style.height = 'auto';
    e.target.style.height = e.target.scrollHeight + offset + 'px';

    handleUpdateQuestionAnswer(questionId, e.target.value);
  }

  useEffect(() => {
    getResponses();
  }, []);

  // Handles displaying the different question types
  const displayQuestion = (q: Question) => {
    // Cannot answer if not current question or response exists
    const answeringDisabled = (q.id !== currentQuestionId) || (responses[q.id] ? true : false) || isPaused || timeIsUp;

    // text question
    if (q.questionTypeId === 1) {
      return (
        <form>
          <div className="mb-3">
            <label className="form-label">{q.questionText}</label>
            <textarea 
              onChange={(e) => handleUpdateTextarea(e, q.id)}
              rows={2}
              style={{resize: "none"}}
              className="form-control" 
              placeholder="Type your answer here."
              value={responses[q.id] ? responses[q.id] : questionAnswers[q.id]}
              disabled={answeringDisabled}
            />
            <br/>
            {showingLimitMessage ? <p className="text-danger">Character limit reached</p> : null}
            <button 
              onClick={(e) => {
                e.preventDefault();
                setCurrentQuestionId(q.id);
                toggleVerify(true);
              }}
              className="btn btn-primary"
              disabled={answeringDisabled}>
                Submit
            </button>
          </div>

        </form>
      );
    }

    // Multiple choice question
    if (q.questionTypeId === 2) {
      return (
      
      <div>
        <p><b>{q.questionText}</b></p>
        {q.choices.map((choice: Choice, i: number) => {
          return (
            <button 
              key={i}  
              onClick={(e) => {
                e.preventDefault();
                setCurrentQuestionId(q.id);
                handleUpdateQuestionAnswer(q.id, choice.name);
                toggleVerify(true)
              }}
              style={{marginRight: "20px", border: responses[q.id] === choice.name ? "2px solid #4f4f4f" : "0px"}}
              className="btn btn-primary"
              disabled={answeringDisabled}>
                {choice.name}
            </button>
          )
        })}

      </div>

    )
    }

  }

  useEffect(() => {
    // Map through questions to make an array to keep track of answers
    let newQuestionAnswers: QuestionAnswers = {};

    questions.forEach(q => {
      newQuestionAnswers[q.id] = ""
    });

    setQuestionAnswers(newQuestionAnswers)

  }, []);


  return (
    <div className="questions">

      <h2>Questions</h2>
      
      <Modal show={showingFeedback} onHide={() => toggleShowingFeedback(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Feedback</h4>
        </Modal.Header>
        <Modal.Body>
          <p>{feedback}</p>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => toggleShowingFeedback(false)}>Ok</Button>
          </Modal.Footer> 
      </Modal>

      <Modal show={verifying} onHide={() => toggleVerify(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Please confirm</h4>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to submit this question? You cannot edit your answer once it's been submitted.</h5>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={e => handleSubmit(e)}>Submit</Button>
          </Modal.Footer> 
      </Modal>

      {questions.map((q: Question) => {
        return (
          <div className="row" key={q.id} style={{marginBottom: "20px"}}>
            {displayQuestion(q)}
          </div>
        )
       })}

      <hr/>

    </div>
  );
}

export default Questions;
