import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Alert, Modal, Button, Toast } from "react-bootstrap";
import { get, del } from "superagent";
import { Result } from "../../types";

const { Parser } = require('json2csv');

type Results = {
  [userId: number]: {
    userName: string,
    id: number,
    pages: {
      [pageId: number]: {
        title: string,
        responses: {
          [responseId: number]: {
            id: number
            questionText: string,
            responseText: string,
            tags: string,
            negativeFeedback: string,
            positiveFeedback: string
          }
        }
      }
    }
  }
}

function Results() {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [results, setResults] = useState<Results>({});
  const [showConfirmDeleteResponse, toggleShowConfirmDeleteResponse] = useState<boolean>(false);
  const [selectedResponseId, setSelectedResponseId] = useState<number>();
  const [showToast, toggleShowToast] = useState<boolean>(false);

  let { caseId } = useParams();

  // Get all case studies
  const getResults = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/response/case/${caseId}`)
      .withCredentials()
      .then((res: any) => {

        let questionsById: any = {};
        let newResults: any = {};

        // Create object of questions for all pages
        res.body.data.pages.forEach((page: any) => {
          page.questions.forEach((q: any) => {
            questionsById[q.id] = {...q, pageTitle: page.title};
          })
        });

        // Sort results into mappable object for UI
        res.body.data.users.forEach((user: any) => {

          if (user.responses.length > 0) {
            newResults[user.id] = {userName: user.userName, id: user.id};
            user.responses.forEach((r: any) => {
              const pageId = questionsById[r.questionId].pageId;
              const pageTitle = questionsById[r.questionId].pageTitle;
  
              if (!newResults[user.id].pages) {
                newResults[user.id].pages = {}
              }
  
              if (!newResults[user.id].pages[pageId]) {
                newResults[user.id].pages[pageId] = {title: pageTitle}
              }
              
              if (!newResults[user.id].pages[pageId].responses) {
                newResults[user.id].pages[pageId].responses = {}
              }
  
              newResults[user.id].pages[pageId].responses[r.id] = {
                ...r, 
                questionText: questionsById[r.questionId].questionText,
                tags: questionsById[r.questionId].tags,
                negativeFeedback: questionsById[r.questionId].negativeFeedback,
                positiveFeedback: questionsById[r.questionId].positiveFeedback
              };
  
            });
          }

        });

        setResults(newResults);
        return;
      }).catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
    });
    
  };

  // Download all user results
  const downloadResults = (data: Results) => {
    let flattenedResults: Array<{}> = [];

    // Flatten results into 1d array
    Object.keys(data).forEach((userId: any) => {
      const user = data[userId];
      const pages = data[userId].pages
      Object.keys(pages).forEach((pageId: any) => {
        const page = pages[pageId];
        const responses = pages[pageId].responses;
        Object.keys(responses).forEach((responseId: any) => {
          const response = responses[responseId]
          flattenedResults.push({
            "User": user.userName,
            "Page": page.title,
            "Question Text": response.questionText,
            "Response": response.responseText,
            "Tags": response.tags,
            "Negative Feedback": response.negativeFeedback,
            "Positive Feedback": response.positiveFeedback
          });
        });
      });
    });
        
    download(flattenedResults)
  }

  const download = (flattenedResults: Array<{}>) => {
    const parser = new Parser({fields: ['User', 'Page', 'Question Text', 'Response', 'Tags', 'Negative Feedback', 'Positive Feedback']});
    const csv = parser.parse(flattenedResults);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url)
    a.setAttribute('download', `case_${caseId}_results.csv`);
    a.click()
    a.remove();
  }

  // Delete individual response
  const deleteResponse = async () => {
    if (selectedResponseId) {
      await del(`${process.env.REACT_APP_API_URL}/api/response/${selectedResponseId}`)
      .withCredentials()
      .then(() => {
        getResults();
      })
      .catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });
    }
  };


  useEffect(() => {
    getResults();
  }, []);

  return (
    <div className="col">

      <Modal show={showConfirmDeleteResponse} onHide={() => toggleShowConfirmDeleteResponse(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Delete Answer</h4>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this answer? The user will be able to answer it again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => toggleShowConfirmDeleteResponse(false)}>Cancel</Button>
          <Button variant="btn btn-danger" onClick={() => {
            deleteResponse();
            toggleShowConfirmDeleteResponse(false);
            }}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <hr className="my-4"/>

      <p 
        className="float-end" 
        style={{display: results && Object.keys(results).length > 0 ? "initial" : "none"}}>
        <button 
          type="button" 
          className="btn btn-primary mt-2" 
          onClick={() => downloadResults(results)}>
          <i className="bi bi-file-earmark-arrow-down-fill"></i>&nbsp;&nbsp;Download CSV
        </button>
      </p>
            
      <h3>Results</h3>
      
      <div className="accordion mt-5" id="resultsAccordion">

        {results && Object.keys(results).length > 0 ? 
          (Object.keys(results).map((userId: any) => {
            const user = results[userId];

            return (
              <div className="accordion-item" id="resultsAccordion" key={userId}>
                <h2 className="accordion-header" id={`result-user-${user.id}`}>
                  <button 
                    type="button" 
                    className="btn btn-primary csv-button" 
                    onClick={() => downloadResults({0: user})}>
                    <i className="bi bi-file-earmark-arrow-down-fill"></i>
                    &nbsp;&nbsp;Download CSV
                    </button>
                  <button 
                    className="accordion-button" 
                    type="button" 
                    data-bs-toggle="collapse" 
                    data-bs-target={`#result-user-${user.id}`}
                    aria-controls={`result-user-${user.id}`}>
                    {user.userName}
                  </button>
                </h2>
                <div 
                  id={`result-user-${user.id}`} 
                  className="accordion-collapse collapse" 
                  data-bs-parent="resultsAccordion">
                  <div className="accordion-body">

                    {user.pages && Object.keys(user.pages).length > 0 ?
                    
                      (Object.keys(user.pages).map((pageId: any) => {
                        const page = user.pages[pageId];
                        const responses = user.pages[pageId].responses;

                        return (
                          <div className="accordion" id="user1Accordion" key={pageId}>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="user1HeadingOne">
                              <button 
                                className="accordion-button" 
                                type="button" data-bs-toggle="collapse" 
                                data-bs-target="#user1Page1" 
                                aria-expanded="false" 
                                aria-controls="user1Page1">
                                {page.title}
                              </button>
                            </h2>
                              <div 
                                id="user1Page1" 
                                className="accordion-collapse collapse" 
                                aria-labelledby="user1HeadingOne" 
                                data-bs-parent="#user1Accordion">

                                <div className="accordion-body">
                                  <table className="table table-striped user-page-table">
                                    <thead>
                                      <tr>
                                        <th>Question text</th>
                                        <th>Answer</th>
                                        <th>Tags</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                            
                                      {Object.keys(responses).map((responseId: any) => {
                                        const response = responses[responseId];

                                        return (
                                          <tr key={responseId}>
                                            <td>{response.questionText}</td>
                                            <td><div>{response.responseText}</div></td>
                                            <td>{response.tags}</td>
                                            <td>
                                              <button className="btn btn-sm btn-danger" onClick={() => {
                                                toggleShowConfirmDeleteResponse(true);
                                                setSelectedResponseId(response.id);
                                                }}>
                                                <i className="bi bi-x-circle-fill"></i>&nbsp;&nbsp;Delete answer
                                              </button>
                                            </td>
                                          </tr>
                                        )})}

                                    </tbody>
                                  </table>
                                </div>

                              </div>
                            </div>
                          </div>
                        )
                      }))
                    : null}
                    
                  </div>
                </div>
              </div>
            )



          }))
        
        
        : "There are no results yet."}

      </div>

              
      {showAlert ? 
        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
          <strong>Error: </strong> {alertMessage}
        </Alert>
        : null 
      }

    </div>
  );
}

export default Results;
