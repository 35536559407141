import { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Alert, Modal, Form, Row, InputGroup, Button, Toast } from "react-bootstrap";
import { get, post, del } from "superagent";
import { User } from "../../types";

type EmailLoadingStatus = {[userId: string]: boolean};

function Users() {
  const [error, setError] = useState<string>("");
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [users, setUsers] = useState<Array<User>>([]);
  const [showAddUserModal, toggleShowAddUserModal] = useState<boolean>(false);
  const [newUserValues, setNewUserValues] = useState<any>({});
  const [emailLoadingStatuses, setEmailLoadingStatuses] = useState<EmailLoadingStatus>({});
  const [emailAllLoadingStatus, toggleEmailAllLoadingStatus] = useState<boolean>(false);
  const [showToast, toggleShowToast] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");

  let { caseId } = useParams();

  // Get all case studies
  const getUsers = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/user/case/${caseId}`)
      .withCredentials()
      .then((res: any) => {
        setUsers(res.body.data);

        // Set email loading status for all users
        let newEmailLoadingStatuses: EmailLoadingStatus = {};
        res.body.data.forEach((user: User) => {
          newEmailLoadingStatuses[user.id.toString()] = false;
        })
        setEmailLoadingStatuses(newEmailLoadingStatuses);

        return;
      }).catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
    });
    
  };

  const addUser = async (e: React.FormEvent<HTMLElement>) => {
    e.preventDefault();

    await post(`${process.env.REACT_APP_API_URL}/api/user/${caseId}`)
      .withCredentials()
      .send({...newUserValues})
      .then(() => {
        getUsers();
      })
      .catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });


    toggleShowAddUserModal(false);
  };

  const deleteUser = async (id: number) => {
    await del(`${process.env.REACT_APP_API_URL}/api/user/${id}?caseid=${caseId}`)
      .withCredentials()
      .catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });
    await getUsers();
  };

  // Sets a user's email status (sending or not sending)
  const handleSetEmailLoadingStatuses = (userId: string, isLoading: boolean) => {
    let newEmailLoadingStatuses: EmailLoadingStatus = Object.assign({}, emailLoadingStatuses);
    newEmailLoadingStatuses[userId] = isLoading;
    setEmailLoadingStatuses(newEmailLoadingStatuses);
  }

  // Sends invite to single user
  const sendInvite = async (id: number) => {

    // Set email loading status
    handleSetEmailLoadingStatuses(id.toString(), true);

    await post(`${process.env.REACT_APP_API_URL}/api/email/${id}/${caseId}`)
      .withCredentials()
      .then((res) => {
        setToastMessage("Email sent!")
        toggleShowToast(true);
      })
      .catch((err: any) => {
        if (err.response.text) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });

    // Set email loading status again
    handleSetEmailLoadingStatuses(id.toString(), false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  // Sends invite to all users in case
  const sendInviteAll = async () => {

    // Set email loading status
    toggleEmailAllLoadingStatus(true);

    await post(`${process.env.REACT_APP_API_URL}/api/email/${caseId}`)
      .withCredentials()
      .then((res) => {
        setToastMessage("All Emails sent!")
        toggleShowToast(true);
      })
      .catch((err: any) => {
        if (err.response.text) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });

    // Set email loading status again
    toggleEmailAllLoadingStatus(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="col">

      <div style={{ position: "absolute", bottom: "40px", left: "40px", color: "white" }}>
        <Toast
          onClose={() => toggleShowToast(false)}
          show={showToast}
          animation={true}
          delay={3000}
          bg={'success'}
          autohide>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </div>

      <Modal show={showAddUserModal} onHide={() => toggleShowAddUserModal(false)}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Add user</h4>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => addUser(e)}>
            <Row className="mb-3">

              <Form.Group controlId="validationNewCase">
                <Form.Label>Username:</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="title"
                    placeholder="Enter Username"
                    aria-describedby="inputGroupPrepend"
                    value={newUserValues.name}
                    onChange={(e) => setNewUserValues((values: any) => ({
                      ...values,
                      userName: e.target.value,
                    }))}
                    required
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group controlId="formBasicEmail">
                <br/>
                <Form.Label>Email address</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    aria-describedby="inputGroupPrepend"
                    value={newUserValues.description}
                    onChange={(e) => setNewUserValues((values: any) => ({
                      ...values,
                      email: e.target.value,
                    }))}
                    required
                  />
                </InputGroup>

              </Form.Group>
              <Modal.Footer>

              <Button variant="primary" type="submit" onSubmit={(e) => addUser(e)}>
                Add
              </Button>

              </Modal.Footer>

            </Row>
          </Form>
        </Modal.Body>

      </Modal>
          
      <p className="float-end">
        <button 
          type="button" 
          className="btn btn-primary mt-2"
          onClick={() => toggleShowAddUserModal(true)}>
          Add user + 
        </button>
        &nbsp;
        {emailAllLoadingStatus ? 
          <button className="btn btn btn-primary mt-2">
            <div className="spinner-border spinner-border-sm" role="status"/>
            &nbsp; Sending...
          </button> :
          <button className="btn btn btn-primary mt-2" onClick={sendInviteAll}>
            <i className="bi bi-envelope-fill"></i>&nbsp;&nbsp;Send invite to all
          </button>
        }
      </p>
      <h3>Users</h3>
      <div className="clearfix"></div>

      {users.length === 0 ? "There are no users assigned to this case yet." :

        <table className="table table-striped user-table">
          <thead>
            <tr>
              <th>Username</th>
              <th>Email address</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user: User, i) => {
              if (!user.isInstructor) {

                return (
                  <tr key={i}>
                    <td>{user.userName}</td>
                    <td>{user.email}</td>
                    <td>
                    
                      {emailLoadingStatuses[user.id.toString()] ? 
                        <button className="btn btn-sm btn-primary">
                          <div className="spinner-border spinner-border-sm" role="status"/>
                          &nbsp; Sending...
                        </button> :
                        <button className="btn btn-sm btn-primary" onClick={() => sendInvite(user.id)}>
                          <i className="bi bi-envelope-fill"></i>&nbsp;&nbsp;Send invite
                        </button>
                      } 
                      
                      &nbsp;
                      <button className="btn btn-sm btn-danger" onClick={() => deleteUser(user.id)}>
                        <i className="bi bi-x-circle-fill"></i>&nbsp;&nbsp;Remove
                      </button>
                    </td>
                  </tr>
                );

              }
            })}
          </tbody>
        </table>
      }

    {showAlert ? 
      <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
        <strong>Error: </strong> {alertMessage}
      </Alert>
      : null 
    }

    </div>
  );
}

export default Users;
