import { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup, Row, Toast } from "react-bootstrap";
import { get, post, del } from "superagent";

import Header from "../header";

type CaseStudy = {
  name: string;
  createDate: string;
  description: string;
  id: number;
  modifyDate: string;
}
function InstructorPage() {
  const [error, setError] = useState("");
  const [validated, setValidated] = useState(false);
  const [selectedCaseId, setSelectedCaseId] = useState<number|undefined>(undefined);
  const [showAddCaseModal, toggleAddCaseModal] = useState(false);
  const [showConfirmDelete, toggleConfirmDelete] = useState(false);
  const [showToast, toggleShowToast] = useState(false);
  const [caseStudies, setCaseStudies] = useState<Array<CaseStudy>>([]);
  const [newCaseValues, setNewCaseValues] = useState({
    name: "",
    description: "",
  });

  // Get all case studies
  const getCaseStudies = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/case/`)
      .withCredentials()
      .then((res: any) => {
        setCaseStudies(res.body.data);
        return;
      }).catch((err: any) => {
        if (err.response) {
          setError(err.response.text);
        }
        console.error(err);
      });
  };

  const addCaseStudy = async () => {
    await post(`${process.env.REACT_APP_API_URL}/api/case/`)
      .send({ 
        name: newCaseValues.name ? newCaseValues.name : "Untitled", 
        description: newCaseValues.description })
      .withCredentials()
      .then((res: any) => {
        getCaseStudies();
        toggleShowToast(true);
        return;
      }).catch((err: any) => {
        if (err.response) {
          setError(err.response.text);
        }
        console.error(err);
      });

    // Close dialog
    toggleAddCaseModal(false);
  }

  const deleteCaseStudy = async () => {
    await del(`${process.env.REACT_APP_API_URL}/api/case/${selectedCaseId}`)
      .send({ name: newCaseValues.name, description: newCaseValues.description })
      .withCredentials()
      .then((res: any) => {
        // Update case studies
        getCaseStudies();
        return;
      }).catch((err: any) => {
        if (err.response) {
          setError(err.response.text);
        }
        console.error(err);
      });

    toggleConfirmDelete(false);
  }

  useEffect(() => {
    getCaseStudies();
  }, []);

  return (
    <div>

      <Header title="Case Study Builder" isPaused={false}/>
      
      <main role="main">

        <div className="container">

          <div style={{ position: "absolute", bottom: "40px", left: "40px", color: "white" }}>
            <Toast
              onClose={() => toggleShowToast(false)}
              show={showToast}
              animation={true}
              delay={3000}
              bg={'success'}
              autohide>
              <Toast.Body>Case study created successfully!</Toast.Body>
            </Toast>
          </div>

          <Modal show={showConfirmDelete} onHide={() => toggleConfirmDelete(false)}>
            <Modal.Header closeButton>
              <h4 className="modal-title">Confirm Deletion</h4>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this case study? All content, documents, questions, and resposnes will be deleted. <b className="text-danger">This data cannot be recovered</b>.
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => toggleConfirmDelete(false)}>Cancel</Button>
              <Button variant="danger" onClick={deleteCaseStudy}>Delete</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={showAddCaseModal} onHide={() => toggleAddCaseModal(false)}>
            <Modal.Header closeButton>
              <h4 className="modal-title">Add Case Study</h4>
            </Modal.Header>
            <Modal.Body>
              <Form validated={validated} onSubmit={addCaseStudy}>
                <Row className="mb-3">

                  <Form.Group controlId="validationNewCase">
                    <Form.Label>Name:</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="title"
                        placeholder="Title"
                        aria-describedby="inputGroupPrepend"
                        value={newCaseValues.name}
                        onChange={(e) => setNewCaseValues((values) => ({
                          ...values,
                          name: e.target.value,
                        }))}
                        required
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="validationPassword">
                    <Form.Label>Description:</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        aria-describedby="inputGroupPrepend"
                        value={newCaseValues.description}
                        onChange={(e) => setNewCaseValues((values) => ({
                          ...values,
                          description: e.target.value,
                        }))}
                        required
                      />
                    </InputGroup>
                  </Form.Group>
                </Row>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={addCaseStudy}>Add</Button>
            </Modal.Footer>
          </Modal>

          <div style={{position: "absolute", bottom: "40px", left: "40px", color: "white"}}></div>
  

          <div className="row align-items-center">
            <div className="col">
              <h1 style={{ marginTop: "0px" }}>Your Case Studies</h1>
            </div>
            <div className="col">
              <button className="btn btn-primary float-end" onClick={() => toggleAddCaseModal(true)}>
                Add Case Study
              </button>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4 case-studies-cards">
            {caseStudies.length > 0 ?
              caseStudies.map(((cs: CaseStudy, i) => {
                return (
                  <div className="col" key={i}>
                    <div className="card h-100">
                      <div className="card-header">
                        <h2>{cs.name}</h2>
                      </div>
                      <div className="card-body">
                        <p>{cs.description}</p>
                        <a href={`/instructor/${cs.id}`}>
                          <button className="btn btn-primary case-edit">Edit</button>
                        </a>&nbsp;
                        <button 
                          onClick={() => {
                            setSelectedCaseId(cs.id);
                            toggleConfirmDelete(true);
                          }} 
                          className="btn btn-primary case-delete">
                            Delete
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }))
              : `You don't have any case studies yet. Click "Add Case Study" above to get started.`
            }
          </div>
        </div>

      </main>

    </div>
  );
}

export default InstructorPage;
