import { useState, useEffect, useCallback, FC, MouseEvent } from "react";
import { useParams } from "react-router";
import { Alert } from "react-bootstrap";
import { del, get, post } from "superagent";
import type { DropTargetMonitor } from "react-dnd"
import { useDrop, DndProvider } from "react-dnd"
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend'

import { Document } from "../../types";
import { fileExtensions } from "../../helpers/fileExtensions";

const style: any = {
  border: '1px solid gray',
  height: '15rem',
  width: '15rem',
  padding: '2rem',
  textAlign: 'center',
}

export interface TargetBoxProps {
  onDrop: (files: Array<any>) => void,
  isUploading: boolean
}

interface File {
  name: string,
  content: Blob,
  size: number,
}

export const TargetBox: FC<TargetBoxProps> = (props) => {
  const { onDrop, isUploading } = props
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: any }) {
        if (onDrop) {
          onDrop(item.files)
        }
      },
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        }
      },
    }),
    [props],
  );

  const handleFormClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    document.getElementById("file-upload-form")?.click();
  }

  // Convert FileList to array
  const handleFormFiles = (files: FileList|null) => {
    if (files) {
      const fileArray = Array.from(files);
      onDrop(fileArray);
    }
  }

  return (
    <div className="document-upload" ref={drop} style={style}>
      <i className="bi bi-cloud-upload"></i>
      {isUploading ? 
        <p>Uploading...</p> : 
        <div>
          <p>{canDrop && isOver ? 'Release to drop' : 'Drag & drop files here to upload'}</p>
          <p className="small">or</p>
          <form>
            <input 
              multiple 
              type="file" 
              accept=".doc,.docx,.pdf,ppt,pptx,.jpg,.png,jpeg,.mov,.mp3,mp4"
              id="file-upload-form" 
              onChange={(e) => handleFormFiles(e.target.files)}/>
            <button 
              onClick={(e) => handleFormClick(e)} 
              className="btn btn-primary">
              Browse Files
            </button>
          </form>

        </div>
      }
    </div>
  )
};

const Documents = () => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [isUploading, toggleIsUploading] = useState<boolean>(false);

  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [droppedFiles, setDroppedFiles] = useState<Array<File>>([])
  const [numDroppedFiles, setNumDroppedFiles] = useState<number>(0);

  let { caseId, pageId } = useParams();

  const handleFileDrop = useCallback(
    (files: Array<any>) => {
      if (files) {

        setShowAlert(false);
        toggleIsUploading(true);
        setNumDroppedFiles(files.length);

        const allowedExtensions = Object.keys(fileExtensions);

        files.map((file, i) => {
          const extension = file.name.match(/\.[0-9a-z]+$/i)[0];

          // Verify file is the correct type
          if (extension && (allowedExtensions.indexOf(extension) !== -1)) {
            
            const reader = new FileReader();
            reader.onload = (e: any) => {
              setDroppedFiles((prevState: any) => [
                ...prevState,
                { name: file.name, content: e.target.result, size: file.size },
              ]);
            };
            reader.readAsDataURL(file);
            return file;
          }
          else {
            setShowAlert(true);
            setAlertMessage(`Only the following files are allowed: ${allowedExtensions.map(e => e + " ")}`);
          }

        });
      }
    },
    [setDroppedFiles],
  );

  // Get page documents
  const getDocuments = async () => {
    await get(`${process.env.REACT_APP_API_URL}/api/document?pageid=${pageId}`)
      .withCredentials()
      .then((res: any) => {
        setDocuments(res.body.data);
        return;
      }).catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });
  };

  const sendFiles = async () => {
    await post(`${process.env.REACT_APP_API_URL}/api/upload/${pageId}`)
      .withCredentials()
      .send({files: droppedFiles})
      .then(() => {
        getDocuments();
      }).catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });

    toggleIsUploading(false);
    setNumDroppedFiles(0);
    setDroppedFiles([]);
  }

  const deleteDocument = async (id: number) => {
    await del(`${process.env.REACT_APP_API_URL}/api/document/${id}?pageId=${pageId}`)
      .withCredentials()
      .then(() => {
        getDocuments();
        return;
      }).catch((err: any) => {
        if (err.response) {
          setShowAlert(true);
          setAlertMessage(err.response.text);
        }
        console.error(err);
      });
  }

  useEffect(() => {
    getDocuments();
  }, []);

  useEffect(() => {
    // Once all files are dropped, send post request
    if (numDroppedFiles && (numDroppedFiles === droppedFiles.length)) {
      sendFiles();
    }
  }, [droppedFiles]);

  return (
    <div>
      <h3>Documents</h3>
      <div className="row">
        <div className="col col-3">

          <DndProvider backend={HTML5Backend}>
            <TargetBox 
              onDrop={handleFileDrop} 
              isUploading={isUploading}/>
          </DndProvider>

        </div>
        <div className="col col-9">
          <table className="table table-striped document-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Type</th>
                <th>Size</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {documents.map((document) => {
                const extensionIcon = fileExtensions[document.extension] ? fileExtensions[document.extension] : "bi-question-circle";
                return <tr key={document.id}>
                  <td><a href={`${process.env.REACT_APP_API_URL}/api/document/${caseId}/${document.id}`}>{document.title}</a></td>
                  <td><i className={`bi ${extensionIcon}`}></i></td>
                  <td>{document.size}</td>
                  <td align="right">
                    <button 
                      className="btn btn-sm btn-danger" 
                      onClick={() => deleteDocument(document.id)}>
                      Delete
                      </button>
                    </td>
                </tr>
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Alert variant="danger" show={showAlert} onClose={() =>
        setShowAlert(false)} dismissible>
        <Alert.Heading>Error</Alert.Heading>
        <p>{alertMessage}</p>
      </Alert>

    </div>
  );
}

export default Documents;
